<template>
    <div>
        <v-row dense class="px-0 pt-0 secondary">
            <v-col cols="4" class="px-8 py-5 d-flex flex-column justify-center">
                <div class="text-h5 font-weight-light white--text">
                    {{ site?.orgName }} | <strong>{{ site?.siteName }}</strong>
                </div>
                <div class="text-body-1 font-weight-normal white--text mt-2">
                    {{ site?.addressLine1 }} {{ site?.addressLine2 }} {{ site?.town }} {{ site?.county }} {{
                        site?.postcode }}
                    <!--                    <br />{{-->
                    <!--                        site.startDateTime }}-->
                </div>
            </v-col>
            <!--        <pre>{{ site }}</pre>-->
            <v-col cols="8" class="d-flex justify-end align-center px-5">
                <!-- <AppButton class="mr-3" ><v-icon class="primary--text">icons8-csv</v-icon></AppButton>
              <AppButton class="mr-3" ><v-icon class="primary--text">icons8-pdf</v-icon></AppButton> -->
                <!-- StartDate -->

                <!--                Date Fields & Shortcut Buttons-->
                <div class="pa-4">
                    <!--                    Date Picker-->
                    <div class="d-flex justify-space-between">
                        <!--        <div>{{startDate}} | {{startDateUnix}} | {{ endDate }} {{ endDateUnix }}</div>-->
                        <div style="width: 50%" class="pr-3">
                            <!--                                :min="site.startDateTime ? this.$dayjs(site.startDateTime).format('YYYY-MM-DD') : '2021-01-01'"-->
                            <!--                                :max="this.$dayjs().format('YYYY-MM-DD')"-->
                            <DatePicker BackgroundColor="white" label="Start Date" v-model="startDate" :dense="true"
                                :clearable="false" />
                        </div>
                        <!-- EndDate -->
                        <div style="width: 50%">
                            <!--                                :min="site.startDateTime ? this.$dayjs(site.startDateTime).format('YYYY-MM-DD') : '2021-01-01'"-->
                            <!--                                :max="this.$dayjs().format('YYYY-MM-DD')"-->
                            <DatePicker BackgroundColor="white" label="End Date" v-model="endDate" :clearable="false"
                                :dense="true" />
                        </div>
                    </div>
                    <!--                    Last Week, Last Month, Last Quarter, Last Year-->
                    <div>
                        <div class="d-flex mt-4">
                            <div class="d-flex">
                                <v-icon class="mx-2 text-h4" @click="day = !day"
                                    :class="day ? 'white--text' : 'grey--text'">icons8-sun</v-icon>
                                <v-icon class="mx-2 mr-5 text-h4" @click="night = !night"
                                    :class="night ? 'white--text' : 'grey--text'">icons8-moon-and-stars</v-icon>
                            </div>
                            <VBtn @click.native="setDateField('week')" class="primary mr-1">Last 7 Days</VBtn>
                            <VBtn @click.native="setDateField('month')" class="primary mx-1">Last 30 Days</VBtn>
                            <VBtn @click.native="setDateField('quarter')" class="primary mx-1">Last 90 Days</VBtn>
                            <!--                            <VBtn @click.native="setDateField('year')" class="primary ml-1">Last Year</VBtn>-->
                        </div>
                    </div>
                </div>
                <div>
                    <!--                    15/10/24 - siteCircuits - Should be in dropdown as part of new UI, if client ever approves-->
                    <div>
                        <!--                        {{ selectedSiteCircuits }}-->
                        <!--                        Had to use direct v-select for template slot-->
                        <v-select filled rounded background-color="grey lighten-4" height="52" dense
                            :menu-props="{ top: false, offsetY: true }" class="rounded-of mb-4"
                            v-model="selectedSiteCircuits" multiple :items="circuits" item-text="name" item-value="id"
                            clearable label="Select Circuits" hide-details>
                            <!--                            :disabled="this.site?.useMainMeter === '1'"-->
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                    <span>{{ item.id }}</span>
                                </v-chip>
                                <v-chip v-if="index === 1">
                                    <span>{{ item.id }}</span>
                                </v-chip>
                                <v-chip v-if="index === 2">
                                    <span>{{ item.id }}</span>
                                </v-chip>
                                <span v-if="index === 3" class="grey--text text-caption"> (+{{
                                    selectedSiteCircuits.length - 3 }} others) </span>
                            </template>
                        </v-select>
                    </div>
                    <div class="d-flex">
                        <!--                Update, PDF, Load, Usage-->
                        <VBtn @click.native="calculate" class="primary mx-1"><v-icon
                                class="mr-2">icons8-refresh</v-icon>Update</VBtn>
                        <VBtn class="primary mx-1"
                            @click.native="MIX_goExternal('/site-reports/' + site.id + '?startDate=' + startDate + '&endDate=' + endDate)">
                            <v-icon class="mr-2"> icons8-export-pdf</v-icon>PDF
                        </VBtn>
                        <VBtn @click.native="exportLoadCSV" class="primary mx-1"><v-icon
                                class="mr-2">icons8-csv</v-icon>Load</VBtn>
                        <VBtn @click.native="exportUsageCSV" class="primary mx-1"><v-icon
                                class="mr-2">icons8-csv</v-icon>Usage</VBtn>
                    </div>
                </div>
            </v-col>

            <!--      <v-col cols="12">type: {{ site?.type }} | costPerkWh: {{ site?.costPerkWh }} | costPerLitre: {{ site?.costPerLitre }} | comparisonkWh: {{ site?.comparisonkWh-->
            <!--        }} |</v-col>-->
        </v-row>
        <!-- ESTIMATED -->
        <v-row dense class="px-5 pt-2">
            <!-- Estimated Power Usage -->
            <v-col v-if="showHidden > 10" cols="2">
                <DashCard @click.native="showHidden--" title="Estimated Usage" icon="icons8-speed"
                    :value="estimateTotal_kWh" unit="kWh" description="Power Used" :loading="loading" />
            </v-col>
            <!-- Estimated Diesel Usage -->
            <v-col v-if="showHidden > 10 && site?.type === 'Diesel'" cols="2">
                <DashCard title="Estimated Diesel Usage" icon="icons8-engine" :value="estimateTotal_Diesel" unit="ltr"
                    description="Diesel Used" :loading="loading" />
            </v-col>
            <!-- Estimated Carbon Footprint -->
            <v-col v-if="showHidden > 10" cols="2">
                <DashCard title="Estimated Carbon Footprint" icon="icons8-co2" :value="estimateTotal_CO2" unit="kg"
                    description="C02 Emitted" :loading="loading" />
            </v-col>
            <!-- Estimated Total Cost -->
            <v-col v-if="showHidden > 10" cols="2">
                <DashCard title="Estimated Total Cost" icon="icons8-sales-performance" :value="estimateTotal_Cost"
                    unit="£" description="Spent" :loading="loading" />
            </v-col>
        </v-row>
        <!-- USAGE -->
        <v-row dense class="px-5 pt-2">
            <!--Power Usage-->
            <v-col cols="2">
                <DashCard @click.native="showHidden++" title="Power Usage" icon="icons8-speed"
                    :value="usageTotal_kWh < 0 ? 0 : usageTotal_kWh" unit="kWh" description="Power Used"
                    :loading="loading" />
            </v-col>
            <!-- Diesel Usage-->
            <v-col cols="2" v-if="site?.type === 'Diesel'">
                <DashCard title="Estimated Diesel Usage" icon="icons8-engine"
                    :value="usageTotal_Diesel < 0 ? 0 : usageTotal_Diesel" unit="ltr" description="Diesel Used"
                    :loading="loading" />
            </v-col>
            <!--Carbon Footprint-->
            <v-col cols="2">
                <DashCard title="Carbon Footprint" icon="icons8-co2" :value="usageTotal_CO2 < 0 ? 0 : usageTotal_CO2"
                    unit="kg" description="of CO2" :loading="loading" />
            </v-col>
            <!--Total Cost-->
            <v-col cols="2">
                <DashCard title="Total Cost" icon="icons8-sales-performance"
                    :value="usageTotal_Cost < 0 ? 0 : usageTotal_Cost" unit="£" description="Spent"
                    :loading="loading" />
            </v-col>
            <!-- kWh Per M2 -->
            <v-col cols="2">
                <DashCard title="kWh Per m2" icon="icons8-electrical-threshold" :value="0" unit="kWh"
                    description="Per m2" :loading="loading" />
            </v-col>
            <!--            &lt;!&ndash; Rental Cost &ndash;&gt;-->
            <!--            <v-col cols="2">-->
            <!--                <DashCard title="Rental Cost" icon="icons8-sales-performance" :value="estimateRental_Cost" unit="£" description="Spent" :loading="loading" />-->
            <!--            </v-col>-->
            <!-- Fuel Monitoring -->
            <!--            <v-col cols="2">-->
            <!--                <DashCard title="Fuel Monitoring" icon="icons8-rain-gauge" :value="0" unit="ltr" description="ltr" :loading="loading" />-->
            <!--            </v-col>-->
            <!-- Automation Mode -->
            <v-col cols="2">
                <DashCard title="Automation" icon="icons8-eye" :unit="site?.mode.toUpperCase()" description="Mode"
                    :loading="loading" />
            </v-col>
        </v-row>
        <!-- SAVINGS -->
        <v-row dense class="px-5 pt-2">
            <!-- Energy Saved -->
            <v-col cols="2">
                <DashCard title="Energy Saved" icon="icons8-speed" :value="usageSaving_kWh < 0 ? 0 : usageSaving_kWh"
                    unit="kWh" description="Power Saved" :loading="loading" />
            </v-col>
            <!-- Diesel Saved -->
            <v-col cols="2" v-if="site?.type === 'Diesel'">
                <DashCard title="Diesel Saved" icon="icons8-engine"
                    :value="usageSaving_Diesel < 0 ? 0 : usageSaving_Diesel" unit="ltr" description="Diesel Saved"
                    :loading="loading" />
            </v-col>
            <!-- CO2 Saved -->
            <v-col cols="2">
                <DashCard title="CO2 Saved" icon="icons8-co2" :value="usageSaving_CO2 < 0 ? 0 : usageSaving_CO2"
                    unit="kg" description="CO2 Reduction" :loading="loading" />
            </v-col>
            <!-- Cost Saving -->
            <v-col cols="2">
                <DashCard title="Cost Saving" icon="icons8-sales-performance"
                    :value="usageSaving_Total < 0 ? 0 : usageSaving_Total" unit="£" description="Saved"
                    :loading="loading" />
            </v-col>
            <!-- Net Saving -->
            <v-col cols="2">
                <DashCard title="Net Saving" icon="icons8-sales-performance"
                    :value="usageSaving_NET < 0 ? 0 : usageSaving_NET" unit="£" description="Saving"
                    :loading="loading" />
            </v-col>
            <!-- Water Monitoring -->
            <!--            <v-col cols="2">-->
            <!--                <DashCard title="Water Monitoring" icon="icons8-moisture" :value="0" unit="ltr" description="ltr" :loading="loading" />-->
            <!--            </v-col>-->
            <!-- Average Load -->
            <v-col cols="2">
                <DashCard title="Average Load" icon="icons8-voltmeter" :value="computedAverageLoad" unit="kW"
                    description="Load" :loading="loading" />
            </v-col>

            <!-- CHARTS -->
            <!--Load (W) - Area Chart -->
            <v-col cols="6" class="mt-2">
                <BasicCard>
                    <template v-slot:header>
                        <div class="full-width primary rounded-t-lg d-flex align-center px-4"
                            style="height: 80px !important; overflow: hidden !important">
                            <div class="text-body-1 font-weight-bold white--text text-left ml-4">Load (W)</div>
                            <v-spacer />
                            <v-icon class="primary--text text--lighten-4 text-h4">icons8-area-chart</v-icon>
                        </div>
                    </template>
                    <DropdownAdvanced v-model="loadCircuit" :items="circuits" itemtext="name" itemvalue="id"
                        label="Select Circuit" />
                    <LineChart :loading="loading" :datadown="loadSeries" :height="400" :key="chartKey" xaxislabel="Time"
                        yaxislabel="Load" yaxisunit="W"></LineChart><br />
                </BasicCard>
            </v-col>
            <!-- Hourly Usage (kWh) - Bar Chart -->
            <v-col cols="6" class="mt-2">
                <BasicCard>
                    <template v-slot:header>
                        <div class="full-width primary rounded-t-lg d-flex align-center px-4"
                            style="height: 80px !important; overflow: hidden !important">
                            <div class="text-body-1 font-weight-bold white--text text-left ml-4">Hourly Usage (kWh)
                            </div>
                            <v-spacer />
                            <v-spacer />
                            <AppButton v-if="computedDays === 0" @click.native="downloadHourlyUsage"
                                class="primary mx-1"><v-icon class="mr-2">icons8 -csv</v-icon>Download Hourly Data
                            </AppButton>
                            <v-icon class="primary--text text--lighten-4 text-h4">icons8-bar-chart-2</v-icon>
                        </div>
                    </template>
                    <DropdownAdvanced v-model="usageRangeCircuit" :items="circuits" itemtext="name" itemvalue="id"
                        label="Select Circuit" />
                    <BarChart2 :loading="loading" :datadown="usageRange" :height="400" class="" xaxislabel="Time"
                        yaxislabel="Usage" yaxisunit="kWh" :key="chartKey"></BarChart2><br />
                </BasicCard>
            </v-col>

            <!-- Top 10 Circuits by Usage - Table -->
            <v-col cols="6" class="mt-2 mb-5">
                <BasicCard>
                    <template v-slot:header>
                        <div class="full-width primary rounded-t-lg d-flex align-center px-4"
                            style="height: 60px !important; overflow: hidden !important">
                            <div class="text-body-1 font-weight-bold white--text text-left">Top 10 Circuits by Usage
                            </div>
                            <v-spacer />
                            <v-icon class="primary--text text--lighten-4 text-h4">icons8-list-2</v-icon>
                        </div>
                    </template>
                    <div v-if="loading" style="height: 400px !important"
                        class="d-flex align-center justify-center flex-column">
                        <v-progress-circular :size="100" :width="10" indeterminate
                            color="grey lighten-3"></v-progress-circular>
                        <div
                            class="grey--text text--lighten-1 mt-1 animate__animated animate__flash animate__slower animate__infinite">
                            Loading</div>
                    </div>
                    <div v-else style="height: 400px !important" class="">
                        <table width="100%" class="pa-5">
                            <thead>
                                <tr>
                                    <th align="left">Name</th>
                                    <th align="left">Group</th>
                                    <th align="left">Type</th>
                                    <th align="right">Usage kWh</th>
                                </tr>
                            </thead>
                            <tr v-for="circuit in computedUsageByCircuit" :key="circuit.id">
                                <td class="body-2">{{ circuit.id }}</td>
                                <td class="body-2">{{ circuit.group }}</td>
                                <td class="text-caption font-weight-bold">
                                    <v-chip label small class="grey lighten-2">{{ circuit?.type?.toUpperCase()
                                        }}</v-chip>
                                </td>
                                <td align="right">{{ circuit?.value.toFixed(2) }}</td>
                            </tr>
                        </table>
                    </div>
                </BasicCard>
            </v-col>
            <!-- Usage by Circuit Type (kWh) - Pie Chart -->
            <v-col cols="6" class="mt-2 mb-5">
                <BasicCard>
                    <template v-slot:header>
                        <div class="full-width primary rounded-t-lg d-flex align-center px-4"
                            style="height: 60px !important; overflow: hidden !important">
                            <div class="text-body-1 font-weight-bold white--text text-left">Usage by Circuit Type (kWh)
                            </div>
                            <v-spacer />
                            <v-icon class="primary--text text--lighten-4 text-h4">icons8-circle-chart</v-icon>
                        </div>
                    </template>
                    <PieChart :loading="loading" :datadown="computedUsageByType" :height="400" :key="chartKey"
                        unit="kWh">
                    </PieChart>
                </BasicCard>
            </v-col>
            <!-- Total Usage (kWh) - Area Chart -->
            <v-col cols="6" class="mt-2">
                <BasicCard>
                    <template v-slot:header>
                        <div class="full-width primary rounded-t-lg d-flex align-center px-4"
                            style="height: 80px !important; overflow: hidden !important">
                            <div class="text-body-1 font-weight-bold white--text text-left ml-4">Total Usage (kWh)</div>
                            <v-spacer />
                            <v-icon class="primary--text text--lighten-4 text-h4">icons8-area-chart</v-icon>
                        </div>
                    </template>
                    <DropdownAdvanced v-model="usageCircuit" :items="circuits" itemtext="name" itemvalue="id"
                        label="Select Circuit" />
                    <!--                    <LineChart-->
                    <!--                        :loading="loading"-->
                    <!--                        :datadown="usageTimeseries"-->
                    <!--                        :height="400"-->
                    <!--                        class=""-->
                    <!--                        xaxislabel="Time"-->
                    <!--                        yaxislabel="Usage"-->
                    <!--                        yaxisunit="kWh"-->
                    <!--                        :key="chartKey"-->
                    <!--                    ></LineChart-->
                    <StackedBarChart xaxislabel="Time" :xaxisdata="usageTimeSeriesXAxis" yaxislabel="Load"
                        yaxisunit="kWh" :isDay="day" :isNight="night" :datadown="usageTimeseries"
                        :dayData="usageTimeseriesDay" :nightData="usageTimeseriesNight" :key="chartKey" :height="400"
                        :loading="loading" />
                    <br />
                </BasicCard>
            </v-col>
            <!-- Hourly Usage (kWh) and Temperature (°C) - Bar / Line Chart -->
            <v-col cols="6" class="mt-2">
                <BasicCard>
                    <template v-slot:header>
                        <div class="full-width primary rounded-t-lg d-flex align-center px-4"
                            style="height: 80px !important; overflow: hidden !important">
                            <div class="text-body-1 font-weight-bold white--text text-left">Hourly Usage (kWh) and
                                Temperature (°C)</div>
                            <v-spacer />
                            <v-icon @click="exportLoadandTempCSV()"
                                class="primary--text text--lighten-4 text-h4">icons8-climate-change</v-icon>
                        </div>
                    </template>
                    <DropdownAdvanced v-model="hourlyUsageCircuit" :items="circuits" itemtext="name" itemvalue="id"
                        label="Select Circuit" />
                    <!--                    <pre>{{ usageByHour }}</pre>-->
                    <BarChart :loading="loading" :xaxisdata="usageByHourXAxis" :yaxisdata="usageByHourYAxis"
                        :y2axisdata="tempByHourYAxis" :datadown="dataByHour" :height="425" class="" xaxislabel="Time"
                        yaxislabel="Usage" y2axislabel="Temperature" yaxisunit="kWh" y2axisunit="°C" :key="chartKey">
                    </BarChart>
                </BasicCard>
            </v-col>
            <!--            Hourly Cabin Temperature & Humidity-->
            <!--            <v-col cols="6" class="mt-2">-->
            <!--                <BasicCard>-->
            <!--                    <template v-slot:header>-->
            <!--                        <div class="full-width primary rounded-t-lg d-flex align-center px-4" style="height: 80px !important; overflow: hidden !important">-->
            <!--                            <div class="text-body-1 font-weight-bold white&#45;&#45;text text-left ml-4">Hourly Cabin Temperature & Humidity</div>-->
            <!--                            <v-spacer />-->
            <!--                            <v-spacer />-->
            <!--                            <v-icon class="primary&#45;&#45;text text&#45;&#45;lighten-4 text-h4">icons8-climate-change</v-icon>-->
            <!--                        </div>-->
            <!--                    </template>-->
            <!--                    <DropdownAdvanced v-model="usageRangeBox" :items="computedBoxNames" label="Select Sensor">-->
            <!--                        <template v-slot:selection="data">-->
            <!--                            <v-chip v-if="data.selectedItem" v-on="data.on" close @click:close="data.selectedItem = null">{{ data.selectedItem }}</v-chip>-->
            <!--                        </template>-->
            <!--                    </DropdownAdvanced>-->
            <!--                    <BarChart4-->
            <!--                        :loading="loading"-->
            <!--                        :datadown="cabinDataByHour"-->
            <!--                        :height="425"-->
            <!--                        class=""-->
            <!--                        xaxislabel="Time"-->
            <!--                        yaxislabel="Humidity"-->
            <!--                        y2axislabel="Temperature"-->
            <!--                        yaxisunit="%"-->
            <!--                        y2axisunit="°C"-->
            <!--                        :key="chartKey"-->
            <!--                    ></BarChart4>-->
            <!--                </BasicCard>-->
            <!--            </v-col>-->
            <!--            Hourly Occupancy by Cabin-->
            <!--            <v-col cols="6" class="mt-2">-->
            <!--                <BasicCard>-->
            <!--                    <template v-slot:header>-->
            <!--                        <div class="full-width primary rounded-t-lg d-flex align-center px-4" style="height: 80px !important; overflow: hidden !important">-->
            <!--                            <div class="text-body-1 font-weight-bold white&#45;&#45;text text-left ml-4">Hourly Cabin Occupancy</div>-->
            <!--                            <v-spacer />-->
            <!--                            <v-spacer />-->
            <!--                            <v-icon class="primary&#45;&#45;text text&#45;&#45;lighten-4 text-h4">icons8-climate-change</v-icon>-->
            <!--                        </div>-->
            <!--                    </template>-->
            <!--                    <DropdownAdvanced v-model="usageRangeBoxOccupancy" :items="computedBoxNames" label="Select Sensor">-->
            <!--                        <template v-slot:selection="data">-->
            <!--                            <v-chip v-if="data.selectedItem" v-on="data.on" close @click:close="data.selectedItem = null">{{ data.selectedItem }}</v-chip>-->
            <!--                        </template>-->
            <!--                    </DropdownAdvanced>-->
            <!--                    <BarChart5-->
            <!--                        :loading="loading"-->
            <!--                        :datadown="cabinDataByHour"-->
            <!--                        :height="425"-->
            <!--                        class=""-->
            <!--                        xaxislabel="Time"-->
            <!--                        yaxislabel="Occupancy"-->
            <!--                        y2axislabel="Temperature"-->
            <!--                        yaxisunit="Person(s)"-->
            <!--                        y2axisunit="°C"-->
            <!--                        :key="chartKey"-->
            <!--                    ></BarChart5>-->
            <!--                </BasicCard>-->
            <!--            </v-col>-->
            <!-- Top Groups / Cabins by Usage - Table -->
            <v-col cols="6" class="mt-2 mb-5">
                <BasicCard style="height: 550px !important; overflow: auto !important">
                    <template v-slot:header>
                        <div class="full-width primary rounded-t-lg d-flex align-center px-4"
                            style="height: 80px !important; overflow: hidden !important">
                            <div class="text-body-1 font-weight-bold white--text text-left">
                                Cabin Group Total Usage to {{ $dayjs(endDateUnix).format('DD-MMM-YY') }}
                            </div>
                            <v-spacer />
                            <v-icon class="primary--text text--lighten-4 text-h4">icons8-map-2</v-icon>
                        </div>
                    </template>
                    <!--                    <div>-->
                    <!--                        <pre>{{ computedUsageByGroup }}</pre>-->
                    <!--                    </div>-->
                    <div v-if="loading" style="height: 400px !important"
                        class="d-flex align-center justify-center flex-column">
                        <v-progress-circular :size="100" :width="10" indeterminate
                            color="grey lighten-3"></v-progress-circular>
                        <div
                            class="grey--text text--lighten-1 mt-1 animate__animated animate__flash animate__slower animate__infinite">
                            Loading</div>
                    </div>
                    <div v-else style="height: 400px !important" class="">
                        <table width="100%" class="pa-5">
                            <thead>
                                <tr>
                                    <th align="left">Name</th>
                                    <th align="right">Usage kWh</th>
                                </tr>
                            </thead>
                            <tr v-for="circuit in computedUsageByGroup" :key="circuit.id">
                                <td class="text-h7">{{ circuit.name }}</td>
                                <td class="text-h7" align="right">{{ circuit?.value.toFixed(2) }}</td>
                            </tr>
                        </table>
                    </div>
                </BasicCard>
            </v-col>
            <!-- Top Groups / Cabins by Usage - Table -->
            <v-col cols="6" class="mt-2 mb-5">
                <BasicCard style="height: 550px !important">
                    <template v-slot:header>
                        <div class="full-width primary rounded-t-lg d-flex align-center px-4"
                            style="height: 80px !important; overflow: hidden !important">
                            <div class="text-body-1 font-weight-bold white--text text-left">Cabin Total Usage to {{
                                $dayjs(endDateUnix).format('DD-MMM-YY') }}</div>
                            <v-spacer />
                            <v-icon class="primary--text text--lighten-4 text-h4">icons8-map-2</v-icon>
                        </div>
                    </template>
                    <div v-if="loading" style="height: 400px !important"
                        class="d-flex align-center justify-center flex-column">
                        <v-progress-circular :size="100" :width="10" indeterminate
                            color="grey lighten-3"></v-progress-circular>
                        <div
                            class="grey--text text--lighten-1 mt-1 animate__animated animate__flash animate__slower animate__infinite">
                            Loading</div>
                    </div>
                    <div v-else style="height: 400px !important" class="">
                        <table width="100%" class="pa-5">
                            <thead>
                                <tr>
                                    <th align="left">Name</th>
                                    <th align="right">Usage kWh</th>
                                </tr>
                            </thead>
                            <tr v-for="(location, key) in computedBox" :key="key">
                                <td style="border-bottom: 1px solid lightgrey !important" class="text-h7">{{ key }} - {{
                                    location }}</td>
                                <td style="border-bottom: 1px solid lightgrey !important" class="text-h7" align="right">
                                    {{
                                        getUsageByBox(key) }}</td>
                            </tr>
                        </table>
                    </div>
                </BasicCard>
            </v-col>
        </v-row>
        <!--        <pre>{{ computedUsageByBoxLetter }}</pre>-->
        <br /><br /><br />
    </div>
</template>

<script>
export default {
    name: 'SiteDashboardViewV2',
    data(vm) {
        return {
            selectedSiteCircuits: [],
            day: true,
            night: true,
            showHidden: 0,
            site: null,
            loading: false,
            chartKey: 0,
            startDate: null,
            endDate: null,
            initStartDate: vm.$dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
            initEndDate: vm.$dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            startDateUnix: 0,
            endDateUnix: 0,
            loadSeries: [],
            usageTimeseries: [],
            usageTimeseriesDay: [],
            usageTimeseriesNight: [],
            usageTimeSeriesXAxis: [],
            cabinTempByHour: [],
            cabinHumidityByHour: [],
            cabinOccupancyByHour: [],
            usage_kWh: {
                firstValue: 0,
                lastValue: 0
            },
            stat: {
                powerUsage: 11,
                carbonFootprint: 5,
                totalGridCost: 10,
                powerSaved: 603,
                carbonFootprintGrid: 210,
                totalCostSavingGrid: 804
            },
            totalWLoadX: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00'],
            totalWLoadY: [200, 100, 1200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
            totalWUsageX: ['Usage without Automate', 'Usage with Automate'],
            totalWUsageY: [900, 400],
            conversion: {
                kWhPerLitre: '2.3', // per day
                kWhToDiesel: '0.4347826087',
                kWhToGrid: '0.33',
                //fuelCostPerLitre: "0.65", // £ cost per litre
                dieselLtr: '152', // litres per day
                co2Kg: '2.68',
                co2KgHvo: '0.195',
                co2KgGrid: '0.309',
                // New Values
                kwh_to_diesel: '0.4347826087',
                diesel_to_co2: '2.68',
                grid_kwh_to_co2: '0.309',
                hvo_kwh_to_co2: '0.195'
            },
            // Estimate
            estimateTotal_kWh: 0,
            estimateTotal_Diesel: 0,
            estimateTotal_CO2: 0,
            estimateTotal_Cost: 0,
            estimateRental_Cost: 0,
            // Usage
            usageTotal_kWh: 0,
            usageTotal_Diesel: 0,
            usageTotal_CO2: 0,
            usageTotal_Cost: 0,
            // usageTotal_Rental: 0,
            // estimateRental_Cost: 0,
            // Savings
            usageSaving_kWh: 0,
            usageSaving_Diesel: 0,
            usageSaving_CO2: 0,
            usageSaving_Total: 0,
            usageSaving_NET: 0,
            circuits: [],
            loadCircuit: '',
            usageCircuit: '',
            usageRangeCircuit: '',
            hourlyUsageCircuit: '',
            usageByCircuit: [],
            usageRange: [],
            usageByHour: [],
            usageRangeBox: 'A',
            usageRangeBoxOccupancy: 'A',
            dataByHour: {
                usage: [],
                temperature: []
            },
            cabinDataByHour: {
                humidity: [],
                temperature: [],
                occupancy: []
            },
            usageByHourYAxis: [],
            usageByHourXAxis: [],
            tempByHour: [],
            tempByHourYAxis: [],
            tempByHourXAxis: []
            // energyUsageMap: {
            //     0: 3,
            //     1: 3,
            //     2: 3,
            //     3: 3,
            //     4: 3,
            //     5: 3,
            //     6: 4,
            //     7: 5,
            //     8: 8,
            //     9: 10,
            //     10: 11,
            //     11: 12,
            //     12: 12
            // }
        }
    },
    computed: {
        computedBox() {
            let cabinNames = this.site.cabinNames
            //if no names, set default
            if (cabinNames === '{}') {
                // get site.cabins
                let cabins = this.site.cabins
                if (cabins) {
                    cabins = JSON.parse(cabins)
                    let box = {}
                    // create box, e.g A - Cabin 1, B - Cabin 2
                    for (let i = 1; i < parseInt(cabins); i++) {
                        box[String.fromCharCode(64 + i)] = 'Cabin ' + i
                    }
                    return box
                }
            }

            let box = {}
            if (cabinNames) {
                box = JSON.parse(cabinNames)
            }
            return box
        },
        computedBoxNames() {
            let cabinNames = this.site.cabinNames

            //if no names, set default
            if (cabinNames === '{}') {
                // get site.cabins
                let cabins = this.site.cabins
                if (cabins) {
                    cabins = JSON.parse(cabins)
                    let box = {}
                    // create box, e.g A - Cabin 1, B - Cabin 2
                    for (let i = 1; i < parseInt(cabins); i++) {
                        box[String.fromCharCode(64 + i)] = 'Cabin ' + i
                    }
                    return box
                }
            }

            let box = []
            if (cabinNames) {
                box.push(cabinNames[2])
            }
            return box
        },
        computedComparisonEnergyMap() {
            let comparisonEnergyMap = this.site.comparisonEnergyMap
            let map = {}
            if (comparisonEnergyMap) {
                map = JSON.parse(comparisonEnergyMap)
            }
            // console.log('map: ' + JSON.stringify(map, null, 2))
            return map
        },
        computedUsageByCircuit() {
            // sort by value
            return this.usageByCircuit
                .filter((y) => y.circuitId !== 'total')
                .map((x) => {
                    return {
                        id: x.circuitId,
                        name: this.lookupCircuit(x.circuitId)?.name,
                        group: this.lookupCircuit(x.circuitId)?.group,
                        type: this.lookupCircuit(x.circuitId)?.type,
                        boxLetter: this.lookupCircuit(x.circuitId)?.boxLetter,
                        value: parseFloat(x.difference)
                    }
                })
                .sort((a, b) => {
                    return b.difference - a.difference
                })
                .splice(0, 10)
        },
        computedTotalUsageByCircuit() {
            // sort by value
            return this.usageByCircuit
                .filter((y) => y.circuitId !== 'total')
                .map((x) => {
                    return {
                        name: this.lookupCircuit(x.circuitId)?.name,
                        group: this.lookupCircuit(x.circuitId)?.group,
                        type: this.lookupCircuit(x.circuitId)?.type,
                        boxLetter: this.lookupCircuit(x.circuitId)?.boxLetter,
                        value: parseFloat(x.lastValue)
                    }
                })
                .sort((a, b) => {
                    return b.lastValue - a.lastValue
                })
            // .splice(0, 10)
        },
        // Usage by type
        computedUsageByType() {
            const sums = this.computedTotalUsageByCircuit.reduce((acc, item) => {
                acc[item.type] = (acc[item.type] || 0) + item.value
                return acc
            }, {})

            //remove entry with name UNDEFINED
            delete sums['undefined']

            return Object.entries(sums).map(([type, value]) => {
                return { name: type.toUpperCase(), text: type.toUpperCase(), value: value }
            })
        },
        computedUsageByGroup() {
            let usageByGroup = this.computedTotalUsageByCircuit
            // this.usageTotal_kWh
            // sum the usageByGroup .value
            // let totalUsage = usageByGroup.reduce((acc, item) => {
            //     return acc + item.value
            // }, 0)
            // usageByGroup.push(
            //     {
            //         "name": "Automate",
            //         "group": "Automate",
            //         "type": "automate",
            //         "boxLetter": "ZZZZZ",
            //         "value": this.usageTotal_kWh - totalUsage
            //     },
            // )
            usageByGroup = usageByGroup.reduce((acc, item) => {
                acc[item.group] = (acc[item.group] || 0) + item.value
                return acc
            }, {})
            return Object.entries(usageByGroup)
                .map(([group, value]) => {
                    return { name: group, text: group, value: value }
                })
                .sort((a, b) => parseFloat(b.value) - parseFloat(a.value)) // sort descending
        },
        // Usage By Box Letter
        computedUsageByBoxLetter() {
            let usageByBoxLetter = this.computedTotalUsageByCircuit.reduce((acc, item) => {
                acc[item.boxLetter] = (acc[item.boxLetter] || 0) + item.value
                return acc
            }, {})
            return Object.entries(usageByBoxLetter).map(([boxLetter, value]) => {
                return { name: boxLetter, text: boxLetter, value: value }
            })
        },
        computedUsageByCircuitByHour() {
            return this.usageByCircuit
        },
        computedAverageLoad() {
            return parseFloat((this.loadSeries.reduce((a, b) => a + b[1], 0) / this.loadSeries.length / 1000).toFixed(2))
        },
        computedDays() {
            return parseInt(this.$dayjs(this.endDate).startOf('day').diff(this.$dayjs(this.startDate).startOf('day'), 'day')) + 1
        }
        /*
        // startDateUnix() {
        //   if (this.startDate) {
        //     return this.$dayjs(this.startDate).tz('Europe/London').startOf('day').valueOf();
        //   } else {
        //     return this.$dayjs(this.initStartDate).tz('Europe/London').startOf('day').valueOf();
        //   }
        // },
        // endDateUnix() {
        //   if (this.endDate) {
        //     return this.$dayjs(this.endDate).tz('Europe/London').endOf('day').valueOf();
        //   } else {
        //     return this.$dayjs(this.initEndDate).tz('Europe/London').endOf('day').valueOf();
        //   }
        // },
        // Calculate the Usage Total in kWh by taking the last value kwh from teh first value kwh
        // usageTotal_kWh() {
        //   return (parseFloat(this.usage_kWh.lastValue) - parseFloat(this.usage_kWh.firstValue)).toFixed(2);
        // },
        // Calculate the Usage Total in Diesel by taking the Usage Total in kWh and multiplying by the kWh to Diesel conversion
        // usageTotal_Diesel() {
        //   return (parseFloat(this.usageTotal_kWh) * parseFloat(this.conversion.kwh_to_diesel)).toFixed(2);
        // },
        // Calculate the Usage Total in CO2 by taking the Diesel Usage Total in kWh and multiplying by the Diesel to CO2 conversion
        // usageTotal_CO2() {
        //   return (parseFloat(this.usageTotal_Diesel) * parseFloat(this.conversion.diesel_to_co2)).toFixed(2);
        // },
        // Calculate the Total Cost by Multiplying the Diesel Total Usage by Cost Per Disel frmo Site
        // usageTotal_Cost() {
        //   return (parseFloat(this.usageTotal_Diesel) * parseFloat(this.site.costPerLitre)).toFixed(2);
        // }
        *
         */
    },
    methods: {
        // Helper function to filter tempByHour by selected period: day, night, or both
        filterTempByHour() {
            const filteredTempByHour = {};
            //console.log('Full tempByHour data before filtering:', JSON.stringify(this.tempByHour, null, 2));

            const DAY_START = 6;   // 6 AM UTC
            const DAY_END = 18;    // 6 PM UTC

            Object.keys(this.tempByHour).forEach(key => {
                const timestamp = this.tempByHour[key][0];
                const date = new Date(timestamp);
                const hourOfDay = date.getUTCHours(); // Ensures timestamps are treated as UTC
                //const entryValue = this.tempByHour[key][1];  // Temperature or whatever value is being tracked

                //console.log(`Key: ${key} [Type: ${typeof key}], Timestamp: ${timestamp} [${date}], HourOfDay: ${hourOfDay}, Value: ${entryValue}`);

                if (this.day && this.night) {
                    filteredTempByHour[key] = this.tempByHour[key];
                    //console.log(`✅ Including Hour ${hourOfDay} (Combined Mode)`);
                }
                else if (this.day && !this.night) {
                    if (hourOfDay >= DAY_START && hourOfDay < DAY_END) {
                        filteredTempByHour[key] = this.tempByHour[key];
                        //console.log(`✅ Including Hour ${hourOfDay} (Daytime)`);
                    }
                }
                else if (!this.day && this.night) {
                    if (hourOfDay >= DAY_END || hourOfDay < DAY_START) {
                        filteredTempByHour[key] = this.tempByHour[key];
                        //console.log(`✅ Including Hour ${hourOfDay} (Nighttime)`);
                    }
                }
            });

            //console.log('FilteredTempByHour after filtering:', JSON.stringify(filteredTempByHour, null, 2));
            return filteredTempByHour;
        },
        // Get Site Details
        getSite: async function () {
            const siteID = this.$route.params.id
            // console.log('siteID: ' + siteID)
            await this.REDIS_read('site', siteID).then((result) => {
                // console.log('site: ' + JSON.stringify(result.data, null, 2))
                this.site = result.data
                // TODO - Temporary Additions
                // this.site.mode = 'peakshaving'
                // this.site.costPerkWh = 0.2847 // per kWh
                // this.site.costStandingCharge = 2.63 // per day
                // this.site.rentalCost = 1.0 // per day
                // this.site.type = 'Diesel'
                // this.site.costPerLitre = 0.65 // check this value
            })
        },
        // Get Load Over Time
        async getLoadOverTime() {
            let url = `${process.env.VUE_APP_API_URL}publicV2/timeseries/rangeload`
            let timeseries = ''
            if (this.loadCircuit === '') {
                //check if useMainMeter is "1"
                if (this.site?.useMainMeter === '1') {
                    timeseries = `ts:${this.site.siteId}:mainMeter:load:total`
                } else {
                    timeseries = `ts:${this.site.siteId}:load:total`
                }
            } else {
                timeseries = `ts:${this.site.siteId}:load:${this.loadCircuit}`
            }

            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: null,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'json'
                })
                .then((res) => {
                    // Day / Night
                    if (this.day && this.night) {
                        this.loadSeries = res.data.data.map((item) => {
                            return [item.timestamp, item.value]
                        })
                    } else if (this.day && !this.night) {
                        this.loadSeries = res.data.data.map((item) => {
                            let hour = this.$dayjs(item.timestamp).tz('Europe/London').hour()
                            return hour >= 6 && hour <= 18 ? [item.timestamp, item.value] : [item.timestamp, 0]
                        })
                    } else if (!this.day && this.night) {
                        this.loadSeries = res.data.data.map((item) => {
                            let hour = this.$dayjs(item.timestamp).tz('Europe/London').hour()
                            return hour < 6 || hour > 18 ? [item.timestamp, item.value] : [item.timestamp, 0]
                        })
                    } else {
                        this.loadSeries = []
                    }

                    // console.log('loadSeries: ' + JSON.stringify(this.loadSeries, null, 2))
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        // Get Usage Over Time
        async getUsageOverTime() {
            // console.log('getUsageOverTime > /publicV2/timeseries/range')
            let url = `${process.env.VUE_APP_API_URL}publicV2/timeseries/range`
            let timeseries = ''
            if (this.usageCircuit === '') {
                //check if useMainMeter is "1"
                if (this.site?.useMainMeter === '1') {
                    timeseries = `ts:${this.site.siteId}:mainMeter:usage:total`
                } else {
                    timeseries = `ts:${this.site.siteId}:usage:total`
                }
            } else {
                timeseries = `ts:${this.site.siteId}:usage:${this.usageCircuit}`
            }

            // console.log('this.startDateUnix = ' + this.startDateUnix)
            // console.log('this.startDateTime = ' + this.$dayjs(this.site.startDateTime))
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            // console.log('timeseries: ' + timeseries)s
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: null,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    // Day / Night
                    if (this.day && this.night) {
                        this.usageTimeseries = res.data.data
                    } else if (this.day && !this.night) {
                        this.usageTimeseries = res.data.data.filter((item) => {
                            return this.$dayjs(item[0]).tz('Europe/London').hour() >= 6 && this.$dayjs(item[0]).tz('Europe/London').hour() <= 18
                        })
                    } else if (!this.day && this.night) {
                        this.usageTimeseries = res.data.data.filter((item) => {
                            return this.$dayjs(item[0]).tz('Europe/London').hour() < 6 || this.$dayjs(item[0]).tz('Europe/London').hour() > 18
                        })
                    } else {
                        this.usageTimeseries = []
                    }

                    //create usage by day for the Total usage
                    let usageByDay = []
                    let usageByNight = []
                    const totalDays = this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), 'day')
                    let xAxis = []

                    for (let i = 0; i <= totalDays; i++) {
                        let day = this.$dayjs(this.startDate).add(i, 'day').format('DD-MMM-YY')
                        xAxis.push(day)
                        let overallDayUsage = res.data.data.filter((item) => {
                            return (
                                this.$dayjs(item[0]) >= this.$dayjs(this.startDate).add(i, 'day') &&
                                this.$dayjs(item[0]) <= this.$dayjs(this.startDate).add(i + 1, 'day')
                            )
                        })

                        let dayUsage = []
                        let nightUsage = []

                        for (let j of overallDayUsage) {
                            // this.$dayjs(j[0]).hour() >= 6 &&
                            if (this.$dayjs(j[0]).hour() <= 18) {
                                // console.log('Day: ' + this.$dayjs(j[0]).hour() + ' - ' + j[1])
                                dayUsage.push(j)
                            } else {
                                // console.log('Night: ' + this.$dayjs(j[0]).hour() + ' - ' + j[1])
                                nightUsage.push(j)
                            }
                        }

                        // console.log(nightUsage.length)

                        try {
                            const dayTotal = Math.floor(dayUsage[dayUsage.length - 1][1] - dayUsage[0][1])
                            const nightTotal = Math.floor(nightUsage[nightUsage.length - 1][1] - nightUsage[0][1])
                            usageByDay.push(dayTotal)
                            usageByNight.push(nightTotal)
                        } catch (e) {
                            // console.log('Error: ' + e)
                        }
                    }
                    // console.log('usageByDay: ' + JSON.stringify(usageByDay, null, 2))
                    // console.log('usageByNight: ' + JSON.stringify(usageByNight, null, 2))
                    // console.log('X Axis: ' + JSON.stringify(xAxis, null, 2))
                    this.usageTimeseriesDay = usageByDay
                    this.usageTimeseriesNight = usageByNight
                    this.usageTimeSeriesXAxis = xAxis
                    // console.log('Total Usage (getUsageOverTime): ' + JSON.stringify(this.usageTimeseries[this.usageTimeseries.length - 1][1] - this.usageTimeseries[0][1],
                    //     null, 2))

                    // last value in array
                    // this.usageTotal_kWh = parseFloat(this.usageTimeseries[this.usageTimeseries.length - 1][1].toFixed(2))
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async getUsageTotal() {
            console.log('getUsageTotal > timeseries/usagetotal')

            //SBT 09/10/24 - now uses computedUsageByCircuit
            try {
                let usageByCircuitFiltered = this.usageByCircuit
                    .filter((y) => y.circuitId !== 'total')
                    .map((x) => {
                        // console.log('x = ' + JSON.stringify(x, null, 2))
                        return {
                            id: x.circuitId,
                            name: this.lookupCircuit(x.circuitId)?.name,
                            group: this.lookupCircuit(x.circuitId)?.group,
                            type: this.lookupCircuit(x.circuitId)?.type,
                            boxLetter: this.lookupCircuit(x.circuitId)?.boxLetter,
                            value: parseFloat(x.difference)
                        }
                    })

                let selectedCircuitsLocal = this.selectedSiteCircuits

                //filter by selectedSiteCircuits

                console.log('selectedSiteCircuits: ' + this.selectedSiteCircuits)
                if (this.selectedSiteCircuits.length > 0) {
                    //if ['All']
                    if (this.selectedSiteCircuits.includes('All')) {
                        console.log('ALLLLLLLL*******')
                        //if MainMeter
                        console.log('this.site.useMainMeter: ' + this.site.useMainMeter)
                        if (this.site?.useMainMeter === '1') {
                            console.log('useMainMeter')
                            let url = `${process.env.VUE_APP_API_URL}publicV2/timeseries/usagetotal`
                            console.log('url: ' + url)
                            let timeseries = `ts:${this.site.siteId}:mainMeter:usage:total`
                            console.log('timeseries: ' + timeseries)
                            // Don't get data before site start date
                            if (this.startDateUnix <= this.$dayjs(this.site.startDateTime)) {
                                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
                            }

                            await this.$axios
                                .post(url, {
                                    orgId: this.site.orgId,
                                    timeseries: timeseries,
                                    startTimestamp: this.startDateUnix,
                                    endTimestamp: this.endDateUnix,
                                    day: this.day,
                                    night: this.night,
                                    reverseRange: false,
                                    aggregation: 'avg',
                                    bucketSize: 3600000,
                                    timestampFormat: 'valueOf',
                                    outputFormat: 'json'
                                })
                                .then((res) => {
                                    // console.log('res = ' + JSON.stringify(res, null, 2))
                                    this.usageTotal_kWh = parseFloat(res.data.data.toFixed(2))
                                    console.log('total usage (From MainMeter) = ' + JSON.stringify(res.data.data, null, 2))
                                })
                                .catch((err) => {
                                    console.log(err)
                                })

                            return
                        } else {
                            let url = `${process.env.VUE_APP_API_URL}publicV2/timeseries/usagetotal`
                            let timeseries = `ts:${this.site.siteId}:usage:total`
                            console.log('timeseries = ' + timeseries)
                            // Don't get data before site start date
                            if (this.startDateUnix <= this.$dayjs(this.site.startDateTime)) {
                                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
                            }
                            await this.$axios
                                .post(url, {
                                    orgId: this.site.orgId,
                                    timeseries: timeseries,
                                    startTimestamp: this.startDateUnix,
                                    endTimestamp: this.endDateUnix,
                                    day: this.day,
                                    night: this.night,
                                    reverseRange: false,
                                    aggregation: 'avg',
                                    bucketSize: 3600000,
                                    timestampFormat: 'valueOf',
                                    outputFormat: 'json'
                                })
                                .then((res) => {
                                    this.usageTotal_kWh = parseFloat(res.data.data.toFixed(2))
                                    console.log('total usage = ' + JSON.stringify(res.data.data, null, 2))
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    } else {
                        console.log('do it by circuits')
                        console.log('circuits = ' + JSON.stringify(this.circuits, null, 2))
                        //if Circuits
                        selectedCircuitsLocal = this.circuits.map((circuit) => {
                            return circuit.id
                        })
                        usageByCircuitFiltered = usageByCircuitFiltered.filter((circuit) => {
                            return selectedCircuitsLocal.includes(circuit.id)
                        })
                        //combine all circuits into one total
                        let totalUsage = usageByCircuitFiltered.reduce((acc, item) => {
                            return acc + item.value
                        }, 0)
                        this.usageTotal_kWh = parseFloat(totalUsage.toFixed(2))

                        console.log('totalUsage (From Circuits): ' + parseFloat(totalUsage.toFixed(2)))
                    }
                }

                // console.log('usageByGroup: ' + JSON.stringify(usageByGroup, null, 2))

                // OLD METHOD, using timeseries
                // TODO: Cleanup and remove if client happy
                // let url = `${process.env.VUE_APP_API_URL}publicV2/timeseries/usagetotal`
                // let timeseries = `ts:${this.site.siteId}:usage:total`
                // if (this.site?.useMainMeter === '1') {
                //     timeseries = `ts:${this.site.siteId}:mainMeter:usage:total`
                // }
                // // Don't get data before site start date
                // if (this.startDateUnix <= this.$dayjs(this.site.startDateTime)) {
                //     this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
                // }
                // await this.$axios
                //     .post(url, {
                //         orgId: this.site.orgId,
                //         timeseries: timeseries,
                //         startTimestamp: this.startDateUnix,
                //         endTimestamp: this.endDateUnix,
                //         day: this.day,
                //         night: this.night,
                //         reverseRange: false,
                //         aggregation: 'avg',
                //         bucketSize: 3600000,
                //         timestampFormat: 'valueOf',
                //         outputFormat: 'json'
                //     })
                //     .then((res) => {
                //         this.usageTotal_kWh = parseFloat(res.data.data.toFixed(2))
                //         console.log('total usage = ' + JSON.stringify(res.data.data, null, 2))
                //     })
                //     .catch((err) => {
                //         console.log(err)
                //     })
            } catch (e) {
                console.log('Error: ' + e)
            }
        },
        async getUsageFirstValueByRange() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/firstvaluebyrange`
            let timeseries = `ts:${this.site.siteId}:usage:total`
            if (this.site?.userMainMeter === '1') {
                timeseries = `ts:${this.site.siteId}:mainMeter:usage:total`
            }
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    day: this.day,
                    night: this.night
                })
                .then((res) => {
                    this.usage_kWh.firstValue = res.data.data.value
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async getUsageLastValueByRange() {
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/lastvaluebyrange`
            let timeseries = `ts:${this.site.siteId}:usage:total`
            if (this.site?.useMainMeter === '1') {
                timeseries = `ts:${this.site.siteId}:mainMeter:usage:total`
            }
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    timeseries: timeseries,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    day: this.day,
                    night: this.night
                })
                .then((res) => {
                    this.usage_kWh.lastValue = res.data.data.value
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // Get the Usage Total By Range
        async getUsageRange() {
            let url = `${process.env.VUE_APP_API_URL}publicV2/timeseries/range`
            let t = this
            var timeseries = ''
            if (this.usageRangeCircuit === '') {
                //check if useMainMeter is "1"
                if (this.site?.useMainMeter === '1') {
                    timeseries = `ts:${this.site.siteId}:mainMeter:usage:total`
                } else {
                    timeseries = `ts:${this.site.siteId}:usage:total`
                }
            } else {
                timeseries = `ts:${this.site.siteId}:usage:${this.usageRangeCircuit}`
            }
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            await this.$axios
                .post(url, {
                    orgId: t.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: t.startDateUnix,
                    endTimestamp: t.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'json'
                })
                .then((res) => {
                    // console.log('res data: ' + JSON.stringify(res.data, null, 2))
                    // Day / Night
                    let data = []
                    if (this.day && this.night) {
                        data = res.data.data
                    } else if (this.day && !this.night) {
                        data = res.data.data.filter((item) => {
                            let hour = this.$dayjs(item.timestamp).tz('Europe/London').hour()
                            return hour >= 5 && hour < 18
                        })
                    } else if (!this.day && this.night) {
                        data = res.data.data.filter((item) => {
                            let hour = this.$dayjs(item.timestamp).tz('Europe/London').hour()
                            // Keep all night-time data
                            return hour < 6 || hour >= 17
                        })
                    } else {
                        data = []
                    }

                    // const thirtyMinutesInMilliseconds = 1800000
                    // t.usageRange = data
                    //     .slice(1)
                    //     .map((item, index) => [item.timestamp + thirtyMinutesInMilliseconds, parseFloat(item.value - data[index].value).toFixed(2)]
                    //     )

                    const thirtyMinutesInMilliseconds = 1800000
                    let result = []
                    for (let i = 1; i < data.length; i++) {
                        let item = data[i]
                        let value = [item.timestamp + thirtyMinutesInMilliseconds, parseFloat(item.value - data[i - 1].value).toFixed(2)]
                        result.push(value)
                    }

                    // Function to calculate quartile
                    function quartile(array, percentile) {
                        array.sort((a, b) => a - b)
                        const index = percentile * (array.length - 1)
                        const lower = Math.floor(index)
                        const remainder = index - lower
                        if (array[lower + 1] !== undefined) {
                            return array[lower] + remainder * (array[lower + 1] - array[lower])
                        } else {
                            return array[lower]
                        }
                    }

                    // Extract the values and sort
                    let values = result.map((item) => parseFloat(item[1])).sort((a, b) => a - b)

                    // Compute Q1, Q3, and IQR, default percentile is 0.25 for Q1 and 0.75 for Q3
                    let Q1 = quartile(values, 0.25)
                    let Q3 = quartile(values, 0.8)
                    let IQR = Q3 - Q1

                    // console.clear()
                    //
                    // console.log('Q1: ' + Q1)
                    // console.log('Q3: ' + Q3)
                    // console.log('IQR: ' + IQR)

                    // Define the bounds for outliers

                    //change this to allow for more breathing room for outliers, default is 1.5
                    const breathingRoom = 3

                    let lowerBound = Q1 - breathingRoom * IQR
                    let upperBound = Q3 + breathingRoom * IQR

                    // console.log('lowerBound: ' + lowerBound)
                    // console.log('upperBound: ' + upperBound)
                    // console.log('Results: ')
                    // console.table(result)

                    // Filters our data for non-outliers
                    t.usageRange = result.filter((item) => {
                        let v = parseFloat(item[1])
                        return v >= lowerBound && v <= upperBound
                    })
                    // console.log('usageRange: ')
                    // console.table(t.usageRange)

                    // console.log('usageRange: ' + JSON.stringify(t.usageRange, null, 2))

                    // Remove the 5 AM data point for display if in day mode
                    if (this.day && !this.night) {
                        t.usageRange = t.usageRange.filter(
                            (item) =>
                                this.$dayjs(item[0] - thirtyMinutesInMilliseconds)
                                    .tz('Europe/London')
                                    .hour() !== 5
                        )
                    }

                    // Remove the 5 PM data point for display if in day mode
                    if (!this.day && this.night) {
                        t.usageRange = t.usageRange.filter(
                            (item) =>
                                this.$dayjs(item[0] - thirtyMinutesInMilliseconds)
                                    .tz('Europe/London')
                                    .hour() !== 17
                        )
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async downloadHourlyUsage() {
            const data = this.usageRange.map((row) => ({
                timestamp: this.$dayjs(row[0], 'x').format('DD-MMM-YYYY HH:mm'),
                watts: row[1]
            }))
            let csv = this.$papa.unparse(data)
            this.$papa.download(csv, 'Hourly_Usage')
        },
        async getUsageByCircuit() {
            // console.log('getUsageByCircuit > /publicV2/timeseries/mrange/difference')
            let url = `${process.env.VUE_APP_API_URL}publicV2/timeseries/mrange/difference`
            let t = this
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    startTimestamp: this.startDateUnix,
                    endTimestamp: this.endDateUnix,
                    labels: ['type=usage', `siteId=${t.site.siteId}`],
                    day: this.day,
                    night: this.night
                })
                .then((res) => {
                    // console.log('res data: ' + JSON.stringify(res.data.data, null, 2))
                    // Day / Night
                    this.usageByCircuit = res.data.data
                    // console.log('usageByCircuit: ')
                    // console.table(this.usageByCircuit)
                    // console.log('usageByCircuit: ' + JSON.stringify(t.usageByCircuit.length, null, 2))
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async calculate() {
            this.loading = true
            // console.log('calculate startDate: ' + this.startDate)
            // console.log('calculate endDate: ' + this.endDate)
            if (this.startDate === null) {
                this.startDate = this.initStartDate
            }
            if (this.endDate === null) {
                this.endDate = this.initEndDate
            }
            console.log('startDate: ' + this.startDate)
            console.log('endDate: ' + this.endDate)
            this.startDateUnix = this.$dayjs(this.startDate).tz('Europe/London').startOf('day').valueOf()
            this.endDateUnix = this.$dayjs(this.endDate).tz('Europe/London').endOf('day').valueOf()
            console.log('startDateUnix: ' + this.startDateUnix)
            console.log('endDateUnix: ' + this.endDateUnix)
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            // Get Data
            await this.getLoadOverTime()
            await this.getUsageOverTime()
            await this.getUsageFirstValueByRange()
            await this.getUsageLastValueByRange()
            await this.getUsageByCircuit()
            await this.getUsageRange()
            await this.getUsageByHour()
            await this.getTempByHour()

            // await this.getCabinTempByHour()
            // await this.getCabinHumidityByHour()
            // await this.getCabinOccupancyByHour()
            await this.getUsageTotal()
            this.dataByHour = {
                usage: this.usageByHour,
                temperature: this.tempByHour
            }
            this.cabinDataByHour = {
                humidity: this.cabinHumidityByHour,
                temperature: this.cabinTempByHour,
                occupancy: this.cabinOccupancyByHour
            }
            // console.log('site type: ' + this.site.type)

            // Estimate Calculation

            // kwh_to_diesel: '0.4347826087',
            //     diesel_to_co2: '2.68',
            //     grid_kwh_to_co2: '0.309',
            //     hvo_kwh_to_co2: '0.195'

            // Filter the temperature data once, based on this.day and this.night
            const filteredTempByHour = this.filterTempByHour();
            const filteredTempByHourArray = Object.values(filteredTempByHour);

            switch (this.site.type) {
                case 'Diesel':
                    // Estimate Calculation
                    this.estimateTotal_kWh = parseFloat(await this.calculateEstimatedEnergyUsage(filteredTempByHourArray, this.computedComparisonEnergyMap))
                    this.estimateTotal_Diesel = parseFloat((parseFloat(this.estimateTotal_kWh) * parseFloat(this.conversion.kWhToDiesel)).toFixed(2))
                    this.estimateTotal_CO2 = parseFloat((parseFloat(this.estimateTotal_Diesel) * parseFloat(this.conversion.diesel_to_co2)).toFixed(2))
                    this.estimateTotal_Cost = parseFloat((parseFloat(this.estimateTotal_Diesel) * parseFloat(this.site.costPerLitre)).toFixed(2))
                    // Usage Calculation
                    this.usageTotal_Diesel = parseFloat((parseFloat(this.usageTotal_kWh) * parseFloat(this.conversion.kWhToDiesel)).toFixed(2))
                    this.usageTotal_CO2 = parseFloat((parseFloat(this.usageTotal_Diesel) * parseFloat(this.conversion.diesel_to_co2)).toFixed(2))
                    this.usageTotal_Cost = parseFloat((parseFloat(this.usageTotal_Diesel) * parseFloat(this.site.costPerLitre)).toFixed(2))
                    break
                case 'Green Electricity':
                    // Estimate Calculation
                    this.estimateTotal_kWh = parseFloat(await this.calculateEstimatedEnergyUsage(filteredTempByHourArray, this.computedComparisonEnergyMap))
                    this.estimateTotal_CO2 = parseFloat((parseFloat(this.estimateTotal_kWh) * parseFloat(this.conversion.grid_kwh_to_co2)).toFixed(2))
                    this.estimateTotal_Cost = parseFloat(
                        (parseFloat(this.estimateTotal_kWh) * parseFloat(this.site.costPerkWh) + this.site.costStandingCharge * this.computedDays).toFixed(2)
                    )
                    // Usage Calculation
                    this.usageTotal_CO2 = parseFloat((parseFloat(this.usageTotal_kWh) * parseFloat(this.conversion.grid_kwh_to_co2)).toFixed(2))
                    this.usageTotal_Cost = parseFloat(
                        (parseFloat(this.usageTotal_kWh) * parseFloat(this.site.costPerkWh) + this.site.costStandingCharge * this.computedDays).toFixed(2)
                    )
                    break
                case 'Grid':
                    // Estimate Calculation
                    this.estimateTotal_kWh = parseFloat(await this.calculateEstimatedEnergyUsage(filteredTempByHourArray, this.computedComparisonEnergyMap))
                    this.estimateTotal_CO2 = parseFloat((parseFloat(this.estimateTotal_kWh) * parseFloat(this.conversion.grid_kwh_to_co2)).toFixed(2))
                    this.estimateTotal_Cost = parseFloat(
                        (parseFloat(this.estimateTotal_kWh) * parseFloat(this.site.costPerkWh) + this.site.costStandingCharge * this.computedDays).toFixed(2)
                    )
                    // Usage Calculation
                    this.usageTotal_CO2 = parseFloat((parseFloat(this.usageTotal_kWh) * parseFloat(this.conversion.grid_kwh_to_co2)).toFixed(2))
                    this.usageTotal_Cost = parseFloat(
                        (parseFloat(this.usageTotal_kWh) * parseFloat(this.site.costPerkWh) + this.site.costStandingCharge * this.computedDays).toFixed(2)
                    )
                    break
                case 'HVO':
                    // Estimate Calculation
                    this.estimateTotal_kWh = parseFloat(await this.calculateEstimatedEnergyUsage(filteredTempByHourArray, this.computedComparisonEnergyMap))
                    this.estimateTotal_CO2 = parseFloat((parseFloat(this.estimateTotal_kWh) * parseFloat(this.conversion.hvo_kwh_to_co2)).toFixed(2))
                    this.estimateTotal_Cost = parseFloat(
                        (parseFloat(this.estimateTotal_kWh) * parseFloat(this.site.costPerkWh) + this.site.costStandingCharge * this.computedDays).toFixed(2)
                    )
                    // Usage Calculation
                    this.usageTotal_CO2 = parseFloat((parseFloat(this.usageTotal_kWh) * parseFloat(this.conversion.hvo_kwh_to_co2)).toFixed(2))
                    this.usageTotal_Cost = parseFloat(
                        (parseFloat(this.usageTotal_kWh) * parseFloat(this.site.costPerkWh) + this.site.costStandingCharge * this.computedDays).toFixed(2)
                    )
                    break
            }

            // Estimate Rental Cost
            this.estimateRental_Cost = parseFloat(this.computedDays.toFixed(2) * parseFloat(this.site.rentalCost.toFixed(2)))

            // Calulate Savings
            switch (this.site.mode) {
                case 'monitoring':
                    this.usageSaving_kWh = 0
                    this.usageSaving_Diesel = 0
                    this.usageSaving_CO2 = 0
                    this.usageSaving_Total = 0
                    this.usageSaving_NET = 0
                    break
                default:
                    this.usageSaving_kWh = parseFloat((parseFloat(this.estimateTotal_kWh) - parseFloat(this.usageTotal_kWh)).toFixed(2))
                    this.usageSaving_Diesel = parseFloat((parseFloat(this.estimateTotal_Diesel) - parseFloat(this.usageTotal_Diesel)).toFixed(2))
                    this.usageSaving_CO2 = parseFloat((parseFloat(this.estimateTotal_CO2) - parseFloat(this.usageTotal_CO2)).toFixed(2))
                    this.usageSaving_Total = parseFloat((parseFloat(this.estimateTotal_Cost) - parseFloat(this.usageTotal_Cost)).toFixed(2))
                    this.usageSaving_NET = parseFloat((parseFloat(this.usageSaving_Total) - parseFloat(this.estimateRental_Cost)).toFixed(2))
                    break
            }
            this.chartKey++
            this.loading = false
        },
        // Get Circuits
        async getCircuits() {
            let url = `${process.env.VUE_APP_API_URL}public/circuits`
            await this.$axios
                .post(url, {
                    orgId: this.site.orgId,
                    siteId: this.site.siteId
                })
                .then((res) => {
                    this.circuits = res.data.data.documents.map((x) => x.value)

                    //add 'All'
                    this.circuits.unshift({
                        id: 'All',
                        name: 'All',
                        group: 'All',
                        type: 'All',
                        boxLetter: 'All'
                    })

                    // console.log('circuits: ')
                    // console.table(this.circuits)

                    this.selectedSiteCircuits = ['All']
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        // Lookup Circuit
        lookupCircuit(circuitId) {
            let circuit = this.circuits.find((x) => x.id === circuitId)
            if (circuit !== undefined) {
                if (circuit?.group === undefined || circuit?.group === '' || circuit?.group === null) {
                    circuit.group = circuit?.parentGroup || 'Unknown'
                    return circuit
                } else {
                    return circuit
                }
            }
        },
        lookupCircuitName(circuitId) {
            let circuit = this.circuits.find((x) => x.id === circuitId)
            if (circuit !== undefined) {
                return circuit.name
            } else {
                return 'Unknown'
            }
        },
        async getUsageByHour() {
            // console.log('getUsageByHour > /publicV2/timeseries/range')
            let t = this
            let url = `${process.env.VUE_APP_API_URL}publicV2/timeseries/range`
            let timeseries = ''
            if (this.hourlyUsageCircuit === '') {
                timeseries = `ts:${this.site.siteId}:usage:total`
            } else {
                timeseries = `ts:${this.site.siteId}:usage:${this.hourlyUsageCircuit}`
            }
            const thirtyMinutesInMilliseconds = 30 * 60 * 1000
            // let timeseries = `ts:${t.site.siteId}:usage:total`
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            await t.$axios
                .post(url, {
                    orgId: t.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: t.startDateUnix,
                    endTimestamp: t.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    // Log the raw response data
                    // console.log('Raw hourly usage data:', res.data.data)
                    const dataArray = []
                    // const xAxis = []
                    const yAxis = []
                    let data = []
                    // Day / Night
                    if (this.day && this.night) {
                        data = res.data.data
                    } else if (this.day && !this.night) {
                        data = res.data.data.filter((item) => {
                            return this.$dayjs(item[0]).tz('Europe/London').hour() >= 6 && this.$dayjs(item[0]).tz('Europe/London').hour() <= 18
                        })
                    } else if (!this.day && this.night) {
                        data = res.data.data.filter((item) => {
                            return this.$dayjs(item[0]).tz('Europe/London').hour() < 6 || this.$dayjs(item[0]).tz('Europe/London').hour() > 18
                        })
                    } else {
                        data = []
                    }
                    // console.log('res.data.data: ' + JSON.stringify(res.data.data, null, 2))
                    for (let i = 0; i < data.length - 1; i++) {
                        const currentTimestamp = data[i][0]
                        const currentValue = data[i][1]
                        const nextValue = data[i + 1][1]
                        dataArray.push([currentTimestamp + thirtyMinutesInMilliseconds, parseFloat((nextValue - currentValue).toFixed(2))])
                        // xAxis.push(currentTimestamp)
                        yAxis.push(parseFloat((nextValue - currentValue).toFixed(2)))
                    }
                    // Log the processed data array
                    // console.log('Processed hourly usage data:', dataArray)

                    // Function to calculate quartile
                    function quartile(array, percentile) {
                        array.sort((a, b) => a - b)
                        const index = percentile * (array.length - 1)
                        const lower = Math.floor(index)
                        const remainder = index - lower
                        if (array[lower + 1] !== undefined) {
                            return array[lower] + remainder * (array[lower + 1] - array[lower])
                        } else {
                            return array[lower]
                        }
                    }

                    // Extract the values and sort
                    let values = dataArray.map((item) => parseFloat(item[1])).sort((a, b) => a - b)

                    // Compute Q1, Q3, and IQR
                    let Q1 = quartile(values, 0.25)
                    let Q3 = quartile(values, 0.75)
                    let IQR = Q3 - Q1

                    // Define the bounds for outliers
                    let lowerBound = Q1 - 1.5 * IQR
                    let upperBound = Q3 + 1.5 * IQR

                    // Filters our data for non-outliers
                    t.usageByHour = dataArray.filter((item) => {
                        let v = parseFloat(item[1])
                        return v >= lowerBound && v <= upperBound
                    })

                    t.dataByHour = {
                        usage: this.usageByHour,
                        temperature: this.tempByHour
                    }
                })
                .catch((err) => {
                    console.error(err)
                    console.error('Error fetching hourly usage data:', err)
                })
        },
        async getTempByHour() {
            let t = this
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = `ts:${t.site.siteId}:temperature:total`
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            await t.$axios
                .post(url, {
                    orgId: t.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: t.startDateUnix,
                    endTimestamp: t.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    let data = []
                    // // Day / Night
                    // if (this.day && this.night) {
                    data = res.data.data
                    // } else if (this.day && !this.night) {
                    //     data = res.data.data.filter((item) => {
                    //         return this.$dayjs(item[0]).tz('Europe/London').hour() >= 6 && this.$dayjs(item[0]).tz('Europe/London').hour() <= 18
                    //     })
                    // } else if (!this.day && this.night) {
                    //     data = res.data.data.filter((item) => {
                    //         return this.$dayjs(item[0]).tz('Europe/London').hour() < 6 || this.$dayjs(item[0]).tz('Europe/London').hour() > 18
                    //     })
                    // } else {
                    //     data = []
                    // }
                    t.tempByHour = data.map((item) => [item[0], parseInt(item[1])])
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async getCabinTempByHour() {
            let t = this
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = `ts:${t.site.siteId}:temperature:` + this.usageRangeBox
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            await t.$axios
                .post(url, {
                    orgId: t.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: t.startDateUnix,
                    endTimestamp: t.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    let data = []
                    // // Day / Night
                    // if (this.day && this.night) {
                    data = res.data.data
                    // } else if (this.day && !this.night) {
                    //     data = res.data.data.filter((item) => {
                    //         return this.$dayjs(item[0]).tz('Europe/London').hour() >= 6 && this.$dayjs(item[0]).tz('Europe/London').hour() <= 18
                    //     })
                    // } else if (!this.day && this.night) {
                    //     data = res.data.data.filter((item) => {
                    //         return this.$dayjs(item[0]).tz('Europe/London').hour() < 6 || this.$dayjs(item[0]).tz('Europe/London').hour() > 18
                    //     })
                    // } else {
                    //     data = []
                    // }
                    this.cabinTempByHour = data.map((item) => [item[0], parseInt(item[1])])
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async getCabinHumidityByHour() {
            let t = this
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = `ts:${t.site.siteId}:humidity:` + this.usageRangeBox
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            await t.$axios
                .post(url, {
                    orgId: t.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: t.startDateUnix,
                    endTimestamp: t.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    let data = []
                    // // Day / Night
                    // if (this.day && this.night) {
                    data = res.data.data
                    // } else if (this.day && !this.night) {
                    //     data = res.data.data.filter((item) => {
                    //         return this.$dayjs(item[0]).tz('Europe/London').hour() >= 6 && this.$dayjs(item[0]).tz('Europe/London').hour() <= 18
                    //     })
                    // } else if (!this.day && this.night) {
                    //     data = res.data.data.filter((item) => {
                    //         return this.$dayjs(item[0]).tz('Europe/London').hour() < 6 || this.$dayjs(item[0]).tz('Europe/London').hour() > 18
                    //     })
                    // } else {
                    //     data = []
                    // }
                    this.cabinHumidityByHour = data.map((item) => [item[0], parseInt(item[1])])
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async getCabinOccupancyByHour() {
            let t = this
            let url = `${process.env.VUE_APP_API_URL}public/timeseries/range`
            let timeseries = `ts:${t.site.siteId}:occupancy:` + this.usageRangeBoxOccupancy
            // Don't get data before site start date
            if (this.startDateUnix < this.$dayjs(this.site.startDateTime)) {
                this.startDateUnix = this.$dayjs(this.site.startDateTime).tz('Europe/London').startOf('day').valueOf()
            }
            await t.$axios
                .post(url, {
                    orgId: t.site.orgId,
                    timeseries: timeseries,
                    reverseRange: false,
                    aggregation: 'avg',
                    bucketSize: 3600000,
                    startTimestamp: t.startDateUnix,
                    endTimestamp: t.endDateUnix,
                    timestampFormat: 'valueOf',
                    outputFormat: 'array'
                })
                .then((res) => {
                    let data = []
                    // // Day / Night
                    // if (this.day && this.night) {
                    data = res.data.data
                    // } else if (this.day && !this.night) {
                    //     data = res.data.data.filter((item) => {
                    //         return this.$dayjs(item[0]).tz('Europe/London').hour() >= 6 && this.$dayjs(item[0]).tz('Europe/London').hour() <= 18
                    //     })
                    // } else if (!this.day && this.night) {
                    //     data = res.data.data.filter((item) => {
                    //         return this.$dayjs(item[0]).tz('Europe/London').hour() < 6 || this.$dayjs(item[0]).tz('Europe/London').hour() > 18
                    //     })
                    // } else {
                    //     data = []
                    // }
                    this.cabinOccupancyByHour = data.map((item) => [item[0], parseInt(item[1])])
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        async calculateEstimatedEnergyUsage(temperatureData, energyUsageMap) {
            return new Promise((resolve, reject) => {
                try {
                    let totalEnergyUsage = 0
                    // console.log('temperatureData: ' + JSON.stringify(temperatureData, null, 2))
                    // console.log('energyUsageMap: ' + JSON.stringify(energyUsageMap, null, 2))
                    for (const entry of temperatureData) {
                        // Assuming the temperature is rounded to the nearest whole number for lookup
                        let temperature = Math.round(entry[1])
                        if (temperature < 0) {
                            temperature = 0
                        }
                        if (temperature > 19) {
                            temperature = 19
                        }
                        const energyUsage = energyUsageMap[temperature]
                        // console.log('temperature: ' + temperature)
                        if (energyUsage !== undefined && energyUsage !== null && energyUsage !== '') {
                            // console.log('energyUsage: ' + energyUsage)
                            totalEnergyUsage += energyUsage
                        } else {
                            // Handle the case where there's no matching temperature in energyUsageMap
                            console.warn(`No energy data for temperature: ${temperature}`)
                        }
                    }
                    console.log('estimated totalEnergyUsage: ' + totalEnergyUsage)
                    resolve(totalEnergyUsage)
                } catch (error) {
                    reject(error)
                }
            })
        },
        getUsageByBox(box) {
            if (box) {
                let boxData = this.computedUsageByBoxLetter.find((x) => x.name === box)
                if (boxData) {
                    return parseFloat(boxData.value.toFixed(2))
                } else {
                    return 0
                }
            }
        },
        exportLoadCSV() {
            // console.log('exportCSV')
            const data = this.loadSeries.map((row) => ({
                timestamp: this.$dayjs(row[0], 'x').format('DD-MMM-YYYY HH:mm'),
                watts: row[1]
            }))
            let csv = this.$papa.unparse(data)
            this.$papa.download(csv, 'Load_Over_Time')
            //console.log('uparse ' + JSON.stringify(csv, null, 2))
        },
        exportLoadandTempCSV() {
            // console.log('exportCSV')
            const data = this.dataByHour.usage.map((row, index) => ({
                timestamp: this.$dayjs(row[0], 'x').format('DD-MMM-YYYY HH:mm'),
                usage: Math.floor(row[1]),
                temperature: this.dataByHour.temperature[index][1]
            }))
            let csv = this.$papa.unparse(data)
            this.$papa.download(csv, 'Hourly_Usage_And_Temperature')
            //console.log('uparse ' + JSON.stringify(csv, null, 2))
        },
        exportUsageCSV() {
            // console.log('exportCSV')
            // console.log('usageTimeseries: ' + JSON.stringify(this.usageTimeseries, null, 2))
            const data = this.usageTimeseries.map((row) => ({
                timestamp: this.$dayjs(row[0], 'x').format('DD-MMM-YYYY HH:mm'),
                cumulative_usage: row[1]
            }))
            let csv = this.$papa.unparse(data)
            this.$papa.download(csv, 'Cumlative_Usage_Over_Time')
            //console.log('uparse ' + JSON.stringify(csv, null, 2))
        },
        /**
         * Set the date field, based on the range passed in
         * @param range
         */
        setDateField(range) {
            if (range === 'week') {
                this.startDate = this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD')
                this.endDate = this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD')
            } else if (range === 'month') {
                this.startDate = this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD')
                this.endDate = this.$dayjs().format('YYYY-MM-DD')
            } else if (range === 'quarter') {
                this.startDate = this.$dayjs().subtract(1, 'day').subtract(3, 'month').format('YYYY-MM-DD')
                this.endDate = this.$dayjs().format('YYYY-MM-DD')
            } else if (range === 'year') {
                this.startDate = this.$dayjs().subtract(1, 'day').subtract(1, 'year').format('YYYY-MM-DD')
                this.endDate = this.$dayjs().format('YYYY-MM-DD')
            }
            this.calculate()
        }
    },
    watch: {
        /*
        day: {
            handler: function () {
                this.calculate()
            },
            deep: true
        },
        night: {
            handler: function () {
                this.calculate()
            },
            deep: true
        },*/
        loadCircuit: {
            handler: function () {
                this.getLoadOverTime()
            },
            deep: true
        },
        usageCircuit: {
            handler: function () {
                this.getUsageOverTime()
            },
            deep: true
        },
        usageRangeCircuit: {
            handler: function () {
                this.getUsageRange()
            },
            deep: true
        },
        hourlyUsageCircuit: {
            handler: function () {
                // console.log('hourlyUsageCircuit watched')
                this.getUsageByHour()
            },
            deep: true
        },
        selectedSiteCircuits: {
            handler: function () {
                //if cleared, set to all
                if (this.selectedSiteCircuits?.length === 0) {
                    this.selectedSiteCircuits = ['All']
                }

                //if it has more than just ['all'], remove 'all'
                if (this.selectedSiteCircuits.includes('All') && this.selectedSiteCircuits.length > 1) {
                    this.selectedSiteCircuits = this.selectedSiteCircuits.filter((circuit) => circuit !== 'All')
                }

                //changed calculate to run on button click like the rest, to avoid multiple calls
                // this.calculate()
            },
            deep: true
        }
    },
    async created() {
        // console.log('**** CREATED ****')
        this.loading = true
        // this.selectedSiteCircuits.push('All')
        // this.setDateField('week')
        await this.getSite()
        // console.log(this.computedComparisonEnergyMap)
        await this.getCircuits()
        await this.calculate()
        // console.log('loadSeries: ' + JSON.stringify(this.loadSeries, null, 2))
        // await this.getUsageRange()
        // await this.getUsageByCircuit()
        // await this.calculate()
        // await this.getUsageByHour()
        // await this.getTempByHour()
        //
        // this.energyUsageMap = {
        //     0: 3,
        //     1: 3,
        //     2: 3,
        //     3: 3,
        //     4: 3,
        //     5: 3,
        //     6: 4,
        //     7: 5,
        //     8: 8,
        //     9: 10,
        //     10: 11,
        //     11: 12,
        //     12: 12
        // }
        //
        // this.estimateTotal_kWh = await this.calculateEstimatedEnergyUsage(this.tempByHour, this.energyUsageMap)
        //
        // this.dataByHour = {
        //     usage: this.usageByHour,
        //     temperature: this.tempByHour
        // }
        // console.log('computedUsageByCircuit = ' + JSON.stringify(this.computedUsageByCircuit, null, 2))
        this.loading = false
    }
}
</script>
